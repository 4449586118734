.App {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.App-title {
    margin-bottom: 20px;
    text-align: center;
}

.App-form-elem {
    margin-bottom: 20px;

    display: flex;
    gap: 10px;
    align-items: center;

    & > input[type="text"] {
        flex: 1;
        height: 30px;
    }
}

